import React from "react"
import styled from "styled-components"
import Text from "./text"

const H3 = styled.h3`
  margin: 0;
`

const H3Text = ({ children, ...rest }) => {
  return (
    <H3>
      <Text font="xCompressed" size="xl" weight="bold" color="white" {...rest}>
        {children}
      </Text>
    </H3>
  )
}
export default H3Text
