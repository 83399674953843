import React from "react"
import Media from "react-media"
import styled from "styled-components"
import { ParagraphText } from "../../our-research-page/common-elements"
import media from "../../../utils/media"

const QuestionsSection = styled.div`
  background: ${p => p.theme.bgColorMedium};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem;

  ${media.tablet`
    padding: 2rem;
  `};
`
const StyledParagraphHeader = styled(ParagraphText)`
  text-align: center;
  text-transform: uppercase;
  ${media.phone`
  font-size: ${p => p.theme.fontSizeXL};
`};
`

const StyledParagraphText = styled(ParagraphText)`
  text-align: center;
  ${media.phone`
    font-size: ${p => p.theme.fontSizeNormal};
  `};
`

const StyledLink = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: ${p => p.theme.fontColorYellow};

  &:hover {
    color: ${({ theme }) => theme.fontColorYellowHover};
  }
`

export default () => {
  return (
    <QuestionsSection>
      <StyledParagraphHeader font="xCompressed" size="xxxl" weight="bold">
        STAY INFORMED
      </StyledParagraphHeader>
      <StyledParagraphText size="medium" weight="bold">
        <StyledLink
          href="https://mailchi.mp/edf.org/permianmapsignup"
          target="_blank"
          rel="noopener"
        >
          Sign Up
        </StyledLink>{" "}
        to receive updates about PermianMAP or
        <Media
          queries={{
            small: "(max-width: 576px)",
            normal: "(min-width: 577px)",
          }}
          defaultMatches={{ normal: true }}
        >
          {matches => {
            if (matches.normal) {
              return <br />
            } else {
              return <span> </span>
            }
          }}
        </Media>
        email{" "}
        <StyledLink href="mailto:permianmap@edf.org">
          PermianMap@edf.org
        </StyledLink>{" "}
        with questions.
      </StyledParagraphText>
    </QuestionsSection>
  )
}
