import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import styled from "styled-components"
import Text from "../../elements/text/text"
import H3Text from "../../elements/text/h3-text"

const InnerWrapper = styled.div`
  background-color: ${({ theme }) => theme.bgColorDark};
  display: flex;
  padding: 2em;
  justify-content: center;
  @media (max-width: 645px) {
    flex-direction: column;
    align-items: center;
  }
`

const Container = styled.div`
  margin: 0 8px;
`

const ImgContainer = styled(Container)``

const StyledLogo = styled(Img)`
  min-width: 275px;
  height: auto;
  margin: 0;
  @media (max-width: 645px) {
    margin-bottom: 2em;
  }
`

const TextSection = styled(Container)`
  margin: 0;
  flex: 1;
  max-width: 500px;
  min-width: 300px;
  @media (max-width: 645px) {
    margin-left: 2em;
  }
`
const StyledH3Text = styled(H3Text)`
  margin: 0.5em 0.5em;
  white-space: nowrap;
  @media (max-width: 992px) {
    margin-left: 0rem;
  }
`

const StyledText = styled(Text)`
  margin: 0.25em 1em;
  @media (max-width: 992px) {
    margin-left: 0rem;
  }
`
const ColumnSectionWrapper = styled.div`
  display: flex;
  flex: 1;
  @media (max-width: 645px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
`

const ColumnSection = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 150px;
  @media (max-width: 992px) {
    margin-top: 1rem;
  }
  @media (max-width: 645px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
`

const CopyrightFooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.bgColorLight};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
`

const CopyrightFooterContent = styled.div`
  color: ${({ theme }) => theme.fontColorWhite};
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  font-size: ${({ theme }) => theme.fontSizeNormal};
  padding: 1em 0;
  @media (max-width: 645px) {
    font-size: ${({ theme }) => theme.fontSizeSmall};
  }
`

const CopyrightFooterNavLink = styled.a`
  color: ${({ theme }) => theme.fontColorWhite};
  margin: 0 0 0 0.25em;
  text-decoration: none;
  &:before {
    content: "⦚ ";
  }
  &:hover {
    color: ${({ theme }) => theme.fontColorYellow};
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
  @media (max-width: 645px) {
    flex-direction: column;
    align-items: center;
  }
`

const FooterLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  &:hover {
    color: ${({ theme }) => theme.fontColorYellow};
  }
`

const FooterExternalLink = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  &:hover {
    color: ${({ theme }) => theme.fontColorYellow};
  }
`

const Footer = ({ data }) => {
  const footerFields = data.footer.options
  const footerNavFields = data.footer.options.footer_links
  const CopyRightText = `${
    footerFields.footer_copyright
  } ${new Date().getFullYear()} ${footerFields.footer_company}`
  return (
    <>
      <InnerWrapper>
        <FooterLink to="/">
          <ImgContainer>
            <StyledLogo
              objectFit="cover"
              alt="Permian Map Logo"
              fluid={data.Logo236w.childImageSharp.fluid}
            />
          </ImgContainer>
        </FooterLink>
        <ContentWrapper>
          <TextSection>
            <Text size="small">
              PermianMAP is an initiative of Environmental Defense Fund, a
              leading international nonprofit organization. EDF links science,
              economics, law and innovative private-sector partnerships to
              create transformational solutions to the most serious
              environmental problems.
            </Text>
          </TextSection>
          <ColumnSectionWrapper>
            <ColumnSection>
              <StyledH3Text color="yellow">LEARN MORE</StyledH3Text>
              <StyledText size="small">
                <FooterLink to="/our-research">Our Research</FooterLink>
              </StyledText>
              <StyledText size="small">
                <FooterLink to="/flaring-emissions">
                  Flaring Emissions
                </FooterLink>
              </StyledText>
              <StyledText size="small">
                <FooterExternalLink
                  href="https://www.edf.org/climate/oil-and-gas"
                  target="_blank"
                  rel="noopener"
                >
                  About EDF
                </FooterExternalLink>
              </StyledText>
              <StyledText size="small">
                <FooterExternalLink
                  href="https://www.edf.org/sites/default/files/documents/PermianMapMethodology_1.pdf"
                  target="_blank"
                  rel="noopener"
                >
                  FAQ
                </FooterExternalLink>
              </StyledText>
            </ColumnSection>

            <ColumnSection>
              <StyledH3Text color="yellow">CONTACT</StyledH3Text>

              <StyledText size="small">
                <FooterExternalLink href="mailto:permianmap@edf.org">
                  Email Inquiries
                </FooterExternalLink>
              </StyledText>

              <StyledText size="small">
                <FooterExternalLink href="mailto:permianmapmedia@edf.org">
                  Media Relations
                </FooterExternalLink>
              </StyledText>

              <StyledText size="small">
                301 Congress Ave. Suite 1300
                <br />
                Austin, TX 78701
              </StyledText>
            </ColumnSection>
          </ColumnSectionWrapper>
        </ContentWrapper>
      </InnerWrapper>
      <CopyrightFooterContainer>
        <CopyrightFooterContent>
          {CopyRightText}
          {footerNavFields.map((item, idx) => (
            <CopyrightFooterNavLink
              target="_blank"
              rel="noopener"
              href={item.link_location}
              key={idx}
            >
              {item.link_label}
            </CopyrightFooterNavLink>
          ))}
        </CopyrightFooterContent>
      </CopyrightFooterContainer>
    </>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        Logo236w: file(relativePath: { eq: "logo-236w.png" }) {
          childImageSharp {
            fluid(maxWidth: 236, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        footer: wordpressAcfOptions {
          options {
            footer_copyright
            footer_company
            footer_links {
              link_label
              link_location
            }
          }
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)
