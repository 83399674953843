import styled from "styled-components"

const borderReducer = ({ theme, ...rest }) => {
  switch (rest.border) {
    case "primary":
      return theme.borderPrimary
    case "grey":
      return theme.borderPrimaryGrey
    default:
      return theme.borderSecondary
  }
}

const colorReducer = ({ theme, ...rest }) => {
  switch (rest.color) {
    case "grey":
      return theme.fontColorGrey
    default:
      return theme.fontColorWhite
  }
}

const sizeReducer = ({ theme, ...rest }) => {
  switch (rest.size) {
    case "small":
      return theme.fontSizeSmall
    case "medium":
      return theme.fontSizeMedium
    case "large":
      return theme.fontSizeLarge
    case "xl":
      return theme.fontSizeXL
    case "xxl":
      return theme.fontSize2XL
    case "xxxl":
      return theme.fontSize3XL
    case "giant":
      return theme.fontSizeGiant
    default:
      return theme.fontSizeNormal
  }
}

const Button = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: ${borderReducer};
  color: ${colorReducer};
  font-family: ${({ theme }) => theme.fontFamilyCondensed};
  font-size: ${sizeReducer};
  line-height: 1.6875em;
  margin: 1em;
  padding: 0.25em 1em;
  padding-bottom: 1px;
  text-align: center;
  transition: 0.5s all ease-out;
  font-weight: ${({ theme }) => theme.fontWeightMedium};

  &:hover {
    background-color: ${({ theme }) => theme.fontColorYellow};
    border: ${({ theme }) => theme.borderPrimary};
    color: ${({ theme }) => theme.fontColorGrey};

    path {
      fill: ${({ theme }) => theme.fontColorWhite};
    }
  }
`

export default Button
