import React from "react"

import Button from "./button"

const ButtonPrimary = ({ children, ...rest }) => {
  return (
    <Button border="primary" {...rest}>
      {children}
    </Button>
  )
}

export default ButtonPrimary
